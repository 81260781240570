import React from 'react';
import { ChakraProvider, Box, useBreakpointValue } from '@chakra-ui/react';
import NavigationHeader from './components/NavigationHeader';
import FooterBar from './components/GetAQuote';
import theme from "./theme"
import Home from './pages/Home/Home';
import TopHeaderBar from './components/Top';
import Footer from './components/Footer';
import ReactGA from "react-ga4"

if (process.env.REACT_APP_TRACKING_ID) {
  ReactGA.initialize(process.env.REACT_APP_TRACKING_ID)
}


function App() {
  const paddingBottom = useBreakpointValue({ base: "6%", md: "2%" }); // paddingBottom based on viewport
  const paddingTop =  useBreakpointValue({ base: "140px", md: "160px" });

  return (
    <ChakraProvider theme={theme}>
      <Box backgroundColor={"bg"} mx="auto" paddingBottom={paddingBottom} paddingTop={paddingTop} overflowX={"hidden"} >
        <TopHeaderBar />
        <NavigationHeader />
        <Home/>
        <FooterBar/>
        <Footer mb={"8"}/>
      </Box>
    </ChakraProvider>
  );
}

export default App;

