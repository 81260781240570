import {
    Box,
    Heading,
    Text,
    Button,
    UnorderedList,
    ListItem,
    Divider,
    VStack,
    useColorModeValue,
    Container
} from "@chakra-ui/react";
import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "./Banner";
import RVInsurancePic from "../../images/personal-insurance/RVInsurance.jpg"


function RV() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");
    const savingsList = ["Driving History", "Defensive Driving Courses", "Having no prior claims", "Paid-in-Full annual discounts", "Full-time vs. part-time RV use"]

    return (
        <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
            <TopHeaderBar />
            <NavigationHeader />
            <Box pb={"4em"}>
                <Banner header={"RV Insurance"} footer={"Reliable RV Coverage When You Need It in NY, CT & MA"} img={RVInsurancePic} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                What is RV insurance and what does it protect you from?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                RV insurance covers different types of motorhomes:

                                Class A: heavy-duty frames typically a bus or truck that offer plenty of living space
                                Class B: smallest type of RV, usually no longer than 21ft
                                Class C: in between A/B and known as "mini-motorhomes"

                                There are two types of RVs, one that is drivable and one that you tow behind your vehicle. Standard RV insurance protects you from an accident, severe weather damage, fire damage, limited property damage and uninsured motorists. Additional coverage options for protection against water damage, personal injury, equipment damage, personal effects and pest infestation are available.

                                However, it is important to note that RV insurance will not protect you against property theft in the RV or tire damage.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                What determines full-time RV use vs part-time RV use?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                Usually full-time RVers refers to people who use their RV as a permanent residence. All full-time RVers will want insurance coverage that is similar to their homeowner's policy as it is their full-time residence. Typically insurance companies determine full-time use by the number of days it's in use annually. RV use of 150 days or more (approx. 6+ months) is the common "full-time" threshold of use determined by most insurance carriers. Typical full-time RV coverage includes personal liability, medical payments, and loss assessment.

                                Part-time RVers typically have the option of vacation liability insurance which covers you for injuries or damages that occur on short trips.

                            </Text>
                        </Box>

                        <Box
                            p={6}
                            alignSelf="flex-start" // Add this line
                            width="100%" // Add this line
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                How can I save money on my RV insurance?
                            </Heading>

                            <UnorderedList spacing={3} mb={6}>
                                <Text fontSize="lg" mb={"1.5em"}>
                                    Here are some of the following ways you can save money on RV Insurance:
                                </Text>
                                {savingsList.map((type, index) => {
                                    return <ListItem key={index}>{type}</ListItem>;
                                })}
                            </UnorderedList>

                        </Box>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Save Time and Money with Lia Insurance
                            </Heading>
                            <Divider borderColor={borderColor} mb={6} />
                            <Link to="/contact">
                                <Button colorScheme="teal" size="lg">
                                    CONTACT US
                                </Button>
                            </Link>
                        </Box>

                    </VStack>
                </Container>
            </Box>
            <FooterBar />
        </Box>
    );
}

export default RV;