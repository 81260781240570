import {
    Box,
    Heading,
    Text,
    Button,
    UnorderedList,
    ListItem,
    VStack,
    useColorModeValue,
    Container,
} from "@chakra-ui/react";
import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "./Banner";
import MotorcycleInsurancePic from "../../images/personal-insurance/MotorcycleInsurance.jpg"


function Motorcycle() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");
    const savingsList = [
        "Defensive driving course - Good for three years",
        "Pay your insurance payment once a year vs. monthly",
        "Autopay discounts",
        "Accident history",
        "Clean driving record",
        "Good credit history",
        "Shop different carriers - at Lia we do that for you!",
        "Increase your deductible",
        "Bundle your insurance (get home and motorcycle from the same carrier)",
        "Low Mileage discounts",
        "Brand of motorcycle"
    ];

    return (
        <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
            <TopHeaderBar />
            <NavigationHeader />
            <Box pb={"4em"}>
                <Banner header={"Motorcycle Insurance"} footer={"Affordable Motorcycle Insurance in NY, CT & MA"} img={MotorcycleInsurancePic} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h1" size="xl" mb={6}>
                                Affordable Motorcycle Insurance
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                Motorcycle insurance is required by law in New York, Connecticut, and Massachusetts. If you ride uninsured you can get fined, have your license revoked, be sued or even end up in jail. It provides coverage for a bike, scooter, moped, ATV or UTV. Even if you do not ride your motorcycle that often each state has a minimum required coverage for your motorcycle insurance.
                            </Text>
                        </Box>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Does an auto policy cover Motorcycle Insurance?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                No, unless you have added your motorcycle to your policy, it is not covered.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                How much coverage do you need for your motorcycle?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                Motorcycle insurance is required by state and typically has a state minimum. Whether you should buy more coverage depends on your situation. Similarly, the decision to purchase collision coverage depends on the value of your bike. If you got your bike for $800, it probably does not make sense to get collision or comprehensive coverage unless required by the state. Alternatively, if you have a loan, the lender might require you to have collision coverage on your bike. We always recommend getting as much coverage as you can afford. It is always better to be protected.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                Should you get uninsured/underinsured coverage?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                            Yes absolutely. Buy as much uninsured/underinsured coverage as you can afford. Most motorcycle accidents involving cars are the other person's fault and many of them only have the state's minimum coverage.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            w={"100%"}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                How is the cost of motorcycle insurance determined?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                            Some factors that determine your price are riding history, type of bike, age, location and coverage selection.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            w={"100%"}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                How do you save money on motorcycle insurance?
                            </Heading>
                            <UnorderedList spacing={3} mb={6}>
                                {savingsList.map((item, index) => (
                                    <ListItem key={index} fontSize="lg">
                                        {item}
                                    </ListItem>
                                ))}
                            </UnorderedList>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Save Time and Money with Lia Insurance
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                If you need motorcycle insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop different motorcycle insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your motorcycle.
                            </Text>
                            <Link to={"/contact"}>
                                <Button colorScheme="teal" size="lg" borderRadius="8px">
                                    Contact Us
                                </Button>
                            </Link>
                        </Box>
                    </VStack>
                </Container>
            </Box>
            <FooterBar />
        </Box>
    );
}

export default Motorcycle;
