import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  Divider,
  HStack,
  Flex,
} from '@chakra-ui/react';
import Footer from './components/Footer';
import GetAQuote from './components/GetAQuote';
import TopHeaderBar from './components/Top';
import NavigationHeader from './components/NavigationHeader';
import { Link } from 'react-router-dom';

const DefensiveDriving = () => {
  return (
    <Box height="100%" pb={10} paddingTop={"174px"}>
        <TopHeaderBar/>
        <NavigationHeader/>
    <VStack maxW="container.xl" height={"100vh"} w="80%" m="auto" align="center" bg="white" boxShadow="md" borderRadius="md" p={8} >
      <Heading as="h1" size="2xl" my={6}>
        Defensive Driving Information
      </Heading>
      <Flex direction={{ base: 'column', md: 'row' }} >
      <Link to={"https://lia.asicourse.com/courses/search?course_name=&sort_by=course_name"}>
        <VStack spacing={8} align="stretch" width={{ md: '75%' }} >
            <Box p={5} shadow="md" borderWidth="1px">
              <Heading fontSize="xl">Defensive Driving Courses</Heading>
              <HStack my={2}>
                <Text>Click here to view our Course Catalog offered by American Safety, Inc.</Text>
              </HStack>
              <Divider my={4} />
            </Box>
        </VStack>
        </Link>
      </Flex>
    </VStack>
    <GetAQuote/>
    <Footer/>
    </Box>
  );
};

export default DefensiveDriving;