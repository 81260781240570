import { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  SimpleGrid,
  useColorModeValue,
  Link,
  Divider,
  ThemeProvider,
  useMediaQuery,
} from "@chakra-ui/react";
import { motion, AnimatePresence } from "framer-motion";
import { AiOutlineCar, AiOutlineReconciliation } from "react-icons/ai";
import { IoIosUmbrella } from "react-icons/io";
import { HiOutlineOfficeBuilding } from "react-icons/hi";
import {
  MdOutlineWaterDamage,
  MdErrorOutline,
  MdOutlineComputer,
  MdOutlineBusinessCenter,
} from "react-icons/md";
import { GiBeerBottle } from "react-icons/gi";
import { BsPerson } from "react-icons/bs";
import NavigationHeader from "./components/NavigationHeader";
import FooterBar from "./components/GetAQuote";
import theme from "./theme";
import TopHeaderBar from "./components/Top";
import Banner from "./pages/Personal-Insurance/Banner";
import test from "./images/misc/test.png";
import stock from "./images/business-insurance/stock.jpeg";
import Footer from "./components/Footer";
import BusinessOwner from "./images/business-insurance/business-owner.jpg"
import Cyber from "./images/business-insurance/cyber.jpg"
import Directors from "./images/business-insurance/directors.jpg"
import Error from "./images/business-insurance/error.jpg"
import Flood from "./images/business-insurance/flood.jpg"
import Liquor from "./images/business-insurance/liquor.jpg"
import Property from "./images/business-insurance/property.jpg"
import Workers from "./images/business-insurance/workers.jpg"
import Auto from "./images/personal-insurance/CarInsurance.jpg"
import Umbrella from "./images/personal-insurance/UmbrellaInsurance.jpg"

const MotionBox = motion(Box);

// Create a reusable insurance card component
const InsuranceCard = ({
  icon: Icon,
  title,
  description,
  link,
  backgroundImage,
}) => {
  const bg = useColorModeValue("#BC3B4A", "gray.700");

  const [isHovered, setIsHovered] = useState(false);
  const [isSmallScreen] = useMediaQuery("(max-width: 800px)");

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  const backgroundVariants = {
    rest: { opacity: 0 },
    hover: { opacity: 0.85 },
  };

  return (
    <ThemeProvider theme={theme}>
      <MotionBox
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        transition={{ duration: 0.2 }}
        p={6}
        borderRadius="lg"
        borderWidth="1px"
        borderColor="gray.200"
        boxShadow="md"
        textAlign="center"
        fontFamily={"body"}
        position="relative"
        overflow="hidden"
        bgImage={`url(${backgroundImage})`}
        bgSize="cover"
        bgPosition="center"
        paddingTop={"174px"}
      >
        <MotionBox
          variants={backgroundVariants}
          animate={isHovered ? "hover" : "rest"}
          transition={{ type: "spring", damping: 20, stiffness: 300 }}
          bg={bg}
          color="white"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          zIndex="0"
        />

        <Box
          minHeight={isSmallScreen ? "0" : "320px"}
          position="relative"
          zIndex="0"
          color="white"
        >
          <Link href={`/insurance/business/${link}`}>
            <Box
              fontSize={"5xl"}
              mb={4}
              display={"flex"}
              justifyContent={"center"}
              mx={"auto"}
            >
              <Icon />
            </Box>

            <Heading as="h3" size="md" mb={2}>
              {title}
            </Heading>
            <AnimatePresence>
              {isHovered && (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <Text>{description}</Text>
                </motion.div>
              )}
            </AnimatePresence>
          </Link>
        </Box>
      </MotionBox>
    </ThemeProvider>
  );
};

const BusinessInsurance = () => {
  const textColor = useColorModeValue("gray.700", "gray.300");
  const dividerColor = useColorModeValue("gray.200", "gray.700");
  // Add your insurance data here
  const insuranceData = [
    {
      icon: MdOutlineBusinessCenter,
      title: "Business Owners Protection (BOP)",
      description:
        "This combines business property and business liability insurance into one policy. It will protect your business from fire, theft, disaster, bodily injury or property damage.",
      link: "business-owners",
      img: BusinessOwner
    },
    {
      icon: AiOutlineReconciliation,
      title: "General Liability Insurance",
      description:
        "Sometimes referred to as small business liability or commercial liability insurance, it protects your business against claims made by others including bodily injury, property damage, or personal injury. We work with you to determine your business's risks.",
      link: "general-liability",
      img: stock
    },
    {
      icon: AiOutlineCar,
      title: "Commercial Auto Insurance",
      description:
        "This type of policy helps cover vehicles used for business purposes such as cars, trucks, and vans. It covers vehicle damage and driver injury and protects your company's auto property.",
      link: "business-auto",
      img: Auto
    },
    {
      icon: HiOutlineOfficeBuilding,
      title: "Commercial Property Insurance",
      description:
        "This is something every company needs whether you own your own building, lease or work from home. This will protect your building and its contents, as well as exterior features.",
      link: "commercial-property",
      img: Property
    },
    {
      icon: BsPerson,
      title: "Worker's Comp Insurance",
      description:
        "Worker's compensation is required by most states. This provides medical and wage benefits to people who are injured or become ill at work, including injuries in auto accidents. This policy will protect you against having to pay out workers comp to employees.",
      link: "workers-compensation",
      img: Workers
    },
    {
      icon: IoIosUmbrella,
      title: "Commercial Umbrella Insurance",
      description:
        "This provides an extra layer of insurance protection that goes beyond your general liability insurance policy. This compliments your existing insurance policies with additional coverage.",
      link: "commercial-umbrella",
      img: Umbrella
    },
    {
      icon: MdOutlineComputer,
      title: "Cyber Liability Insurance",
      description:
        "This protects your business against company data breaches and other cyber security issues. It is not a matter of will you suffer a breach, but when. We'll help you evaluate the data liability risks your business may have and find a policy to protect one of your most valuable assets - customer data.",
      link: "cybersecurity",
      img: Cyber
    },
    {
      icon: MdErrorOutline,
      title: "Errors & Omissions Liability Insurance (E & O)",
      description:
        "A specialized liability protection policy that covers you against losses not covered by traditional liability insurance. It protects any business that gives advice, makes educated recommendations, or designs solutions.",
      link: "omissions",
      img: Error
    },
    {
      icon: HiOutlineOfficeBuilding,
      title: "Directors & Officers Liability Insurance (D & O)",
      description:
        "This is typically best fit for those who have high-quality executive leadership or boards of directors. It will protect you from lawsuits or claims made against you while working for that business.",
      link: "directors",
      img: Directors
    },
    {
      icon: MdOutlineWaterDamage,
      title: "Commercial Flood Insurance",
      description:
        "This helps protect your business from flood damage and can protect your business's physical location as well as the contents inside. Commercial flood insurance is usually not covered under a general commercial property insurance policy.",
      link: "flood",
      img: Flood
    },
    {
      icon: GiBeerBottle,
      title: "Liquor Liability Insurance",
      description:
        "Do you own a business that sells or distributes alcohol? This type of policy can protect you against bodily injury or property damage that an intoxicated customer caused after your company served them alcohol.",
      link: "liquor",
      img: Liquor
    },
  ];

  return (
    <Box width={"100%"} bg="gray.50" fontFamily={"body"}>
      <TopHeaderBar />
      <NavigationHeader />
      <Banner
        img={test}
        header={"Business Insurance - NY, CT & MA"}
        footer={"Coverage for businesses of all sizes"}
        fontSize={"1.5em"}
      />
      <VStack
        spacing={8}
        alignItems="center"
        mx="auto"
        maxW="container.lg"
        mt={4}
        mb={20}
      >
        <Divider borderColor={dividerColor} mb={4} />
        <Text
          color={textColor}
          fontSize={{ base: "lg", md: "xl" }}
          textAlign="center"
        >
          Business Insurance can offer coverage for unforeseen risks. You have
          invested so much in your business, minimize your risk and protect your
          investment. From replacing damaged property to covering legal costs,
          insurance can help your business through the unexpected.
        </Text>
        <Divider borderColor={dividerColor} mb={4} />

        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={8} w="full">
          {insuranceData.map((insurance, index) => (
            <InsuranceCard
              key={index}
              icon={insurance.icon}
              title={insurance.title}
              description={insurance.description}
              link={insurance.link}
              backgroundImage={insurance.img}
            />
          ))}
        </SimpleGrid>
      </VStack>
      <FooterBar />
      <Footer mb={10} />
    </Box>
  );
};

export default BusinessInsurance;

// import { useState } from 'react';
// import {
//     Box,
//     VStack,
//     Heading,
//     Text,
//     useColorModeValue,
//     Link,
//     Grid,
//     useMediaQuery,
//     ThemeProvider
// } from '@chakra-ui/react';
// import { motion, AnimatePresence } from 'framer-motion';
// import { AiOutlineCar, AiOutlineReconciliation } from 'react-icons/ai'
// import { IoIosUmbrella } from 'react-icons/io'
// import { HiOutlineOfficeBuilding } from 'react-icons/hi'
// import { MdOutlineWaterDamage, MdErrorOutline, MdOutlineComputer, MdOutlineBusinessCenter } from 'react-icons/md'
// import { VscLaw } from 'react-icons/vsc'
// import { GiBeerBottle } from 'react-icons/gi'
// import { BsPerson } from 'react-icons/bs'
// import TopHeaderBar from './components/Top';
// import NavigationHeader from './components/NavigationHeader';
// import theme from "./theme"
// import Banner from './pages/Personal-Insurance/Banner';
// import test from './images/misc/test.png'
// import Footer from "./components/Footer"
// import GetAQuote from "./components/GetAQuote"
// import stock from "./images/business-insurance/stock.jpeg"

// const MotionBox = motion(Box);

// // Create a reusable insurance card component
// const InsuranceCard = ({ icon: Icon, title, description, link, backgroundImage }) => {
//     const bg = useColorModeValue('#BC3B4A', 'gray.700');

//     const [isHovered, setIsHovered] = useState(false);
//     const [isSmallScreen] = useMediaQuery('(max-width: 800px)');

//     const handleMouseEnter = () => setIsHovered(true);
//     const handleMouseLeave = () => setIsHovered(false);

//     const backgroundVariants = {
//         rest: { opacity: 0 },
//         hover: { opacity: 0.85 }
//     };

//     return (
//         <ThemeProvider theme={theme}>
//             <MotionBox
//                 onMouseEnter={handleMouseEnter}
//                 onMouseLeave={handleMouseLeave}
//                 transition={{ duration: 0.2 }}
//                 p={6}
//                 borderRadius="lg"
//                 borderWidth="1px"
//                 borderColor="gray.200"
//                 boxShadow="md"
//                 textAlign="center"
//                 fontFamily={"body"}
//                 position="relative"
//                 overflow="hidden"
//                 bgImage={`url(${backgroundImage})`}
//                 bgSize="cover"
//                 bgPosition="center"
//             >
//                 <MotionBox
//                     variants={backgroundVariants}
//                     animate={isHovered ? "hover" : "rest"}
//                     transition={{ type: "spring", damping: 20, stiffness: 300 }}
//                     bg={bg}
//                     color="white"
//                     position="absolute"
//                     top="0"
//                     left="0"
//                     right="0"
//                     bottom="0"
//                     zIndex="0"
//                 />

//                 <Box minHeight={isSmallScreen ? "0" : "220px"} position="relative" zIndex="0" color="white">
//                     <Link href={`/insurance/personal/${link}`}>
//                         <Box fontSize={"5xl"} mb={4} display={"flex"} justifyContent={"center"} mx={"auto"}>
//                             <Icon />
//                         </Box>

//                     <Heading as="h3" size="md" mb={2}>
//                         {title}
//                     </Heading>
//                     <AnimatePresence>
//                         {isHovered && (
//                             <motion.div
//                                 initial={{ opacity: 0 }}
//                                 animate={{ opacity: 1 }}
//                                 exit={{ opacity: 0 }}
//                                 transition={{ duration: 0.5 }}
//                             >
//                                 <Text>{description}</Text>
//                             </motion.div>
//                         )}
//                     </AnimatePresence>
//                     </Link>
//                 </Box>
//             </MotionBox>
//         </ThemeProvider>
//     );
// };

// const BusinessInsurance = () => {
//     // Add your insurance data here
//     const textColor = useColorModeValue('gray.700', 'gray.300');

//     const insuranceData = [
//         {
//             icon: MdOutlineBusinessCenter,
//             title: "Business Owners Protection (BOP)",
//             description: "This combines business property and business liability insurance into one policy. It will protect your business from fire, theft, disaster, bodily injury or property damage."
//         },
//         {
//             icon: AiOutlineReconciliation,
//             title: "General Liability Insurance",
//             description: "Sometimes referred to as small business liability or commercial liability insurance, it protects your business against claims made by others including bodily injury, property damage, or personal injury. We work with you to determine your business's risks."
//         },
//         {
//             icon: AiOutlineCar,
//             title: "Commercial Auto Insurance",
//             description: "This type of policy helps cover vehicles used for business purposes such as cars, trucks, and vans. It covers vehicle damage and driver injury and protects your company's auto property."
//         },
//         {
//             icon: HiOutlineOfficeBuilding,
//             title: "Commercial Property Insurance",
//             description: "This is something every company needs whether you own your own building, lease or work from home. This will protect your building and its contents, as well as exterior features."
//         },
//         {
//             icon: BsPerson,
//             title: "Worker's Comp Insurance",
//             description: "Worker's compensation is required by most states. This provides medical and wage benefits to people who are injured or become ill at work, including injuries in auto accidents. This policy will protect you against having to pay out workers comp to employees."
//         },
//         {
//             icon: IoIosUmbrella,
//             title: "Commercial Umbrella Insurance",
//             description: "This provides an extra layer of insurance protection that goes beyond your general liability insurance policy. This compliments your existing insurance policies with additional coverage."
//         },
//         {
//             icon: MdOutlineComputer,
//             title: "Cyber Liability Insurance",
//             description: "This protects your business against company data breaches and other cyber security issues. It is not a matter of will you suffer a breach, but when. We'll help you evaluate the data liability risks your business may have and find a policy to protect one of your most valuable assets - customer data."
//         },
//         {
//             icon: MdErrorOutline,
//             title: "Errors & Omissions Liability Insurance (E & O)",
//             description: "A specialized liability protection policy that covers you against losses not covered by traditional liability insurance. It protects any business that gives advice, makes educated recommendations, or designs solutions."
//         },
//         {
//             icon: HiOutlineOfficeBuilding,
//             title: "Directors & Officers Liability Insurance (D & O)",
//             description: "This is typically best fit for those who have high-quality executive leadership or boards of directors. It will protect you from lawsuits or claims made against you while working for that business."
//         },
//         {
//             icon: MdOutlineWaterDamage,
//             title: "Commercial Flood Insurance",
//             description: "This helps protect your business from flood damage and can protect your business's physical location as well as the contents inside. Commercial flood insurance is usually not covered under a general commercial property insurance policy."
//         },
//         {
//             icon: VscLaw,
//             title: "Commercial Crime Insurance",
//             description: "This protects your business against any financial-related losses due to a business-related crime, including theft by forgery, employees, robbery or even an electronic crime."
//         },
//         {
//             icon: GiBeerBottle,
//             title: "Liquor Liability Insurance",
//             description: "Do you own a business that sells or distributes alcohol? This type of policy can protect you against bodily injury or property damage that an intoxicated customer caused after your company served them alcohol."
//         },
//     ];

//     return (
//         <Box width={"100%"}>
//             <TopHeaderBar />
//             <NavigationHeader />
//             <Banner img={test} header={"Business Insurance - NY, CT & MA"} footer={"Coverage for businesses of all sizes"}/>
//             <VStack spacing={8} alignItems="center" mx="auto" maxW="container.lg" mt={4} mb={20}>
//                 <Text color={textColor} fontSize={{ base: 'lg', md: 'xl' }} textAlign="center">
//                     Business Insurance can offer coverage for unforeseen risks.
//                     You have invested so much in your business, minimize your risk and protect your investment.
//                     From replacing damaged property to covering legal costs, insurance can help your business through the unexpected.
//                 </Text>

//                 <Grid columns={{ base: 1, md: 3 }} spacing={8} w="full">
//                     {insuranceData.map((insurance, index) => (
//                         <InsuranceCard
//                             key={index}
//                             icon={insurance.icon}
//                             title={insurance.title}
//                             description={insurance.description}
//                             link={insurance.link}
//                             backgroundImage={stock}
//                         />
//                     ))}
//                 </Grid>
//             </VStack>
//             <GetAQuote/>
//             <Footer mb={12}/>

//         </Box>
//     );
// };

// export default BusinessInsurance;
