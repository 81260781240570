import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Link,
  Divider,
  Button,
  UnorderedList,
  List,
  ListItem
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import Cyber from "../../images/business-insurance/cyber.jpg";

function CyberSecurityInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Cybersecurity Insurance"}
          footer={"Protect Your Networks At An Affordable Price"}
          img={Cyber}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Cyber Security Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Cyber security insurance protects companies from computer-related crimes and damages. If your company's computer system is compromised due to a targeted or unintentional attack, you may be held accountable for the costs of notifying impacted parties and providing credit monitoring, even if the data is not misused. Cyber Security Insurance includes protection against targeted hits like malware, phishing, and even misplaced laptops with sensitive information.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                What does Cyber Security Insurance cover?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                A cyber insurance policy covers privacy, data, and networks.
              </Text>
              <List>
                <ListItem>-Breach costs</ListItem>
                <ListItem>Cyber extortion</ListItem>
                <ListItem>Cyber crime</ListItem>
                <ListItem>Business interruption</ListItem>
                <ListItem>Data recovery</ListItem>
              </List>
              <UnorderedList>
                <ListItem>-Breach costs</ListItem>
                <ListItem>Cyber extortion</ListItem>
                <ListItem>Cyber crime</ListItem>
                <ListItem>Business interruption</ListItem>
                <ListItem>Data recovery</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                Why do I need Cyber Security Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                With hackers growing increasingly dangerous and cyber-attacks increasing more common by the year, company owners must take charge of their computer security and defend themselves. Cyber insurance gives small companies the protection they need to protect their data.
              </Text>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                You need Cyber Security Insurance if your business:
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              Cyber risk affects every company, from large corporations to small businesses that use technology to conduct business.

              </Text>
              <UnorderedList>
                <ListItem>Accepts credit cards or other digital payment types</ListItem>
                <ListItem>Uses computers and mobile devices</ListItem>
                <ListItem>Keeps medical or financial data</ListItem>
                <ListItem>Business interruption</ListItem>
                <ListItem>Stores confidential customer information</ListItem>
              </UnorderedList>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need Cyber Security insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Cyber Security insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default CyberSecurityInsurance;
