import {
  Box,
  Heading,
  Text,
  UnorderedList,
  ListItem,
  VStack,
  useColorModeValue,
  Container,
  Link,
  Divider,
  Button,
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import Flood from "../../images/business-insurance/flood.jpg";

function CommercialFloodInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"Commercial Flood Insurance"}
          footer={"Keep Your Peace Of Mind With Our Commercial Flood Insurance"}
          img={Flood}
          fontSize={"1.5em"}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Commercial Flood Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Commercial flood insurance is insurance that protects your company from flood damage. It protects your company's physical location as well as its contents. Some plans may also cover financial damages incurred as a result of company interruption.

              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What does Flood Insurance cover?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              Flood insurance protects the property and its belongings from flood damage. This includes losses caused by overflowing rivers or streams, intense or persistent rain, storm surge, snow melt, clogged storm drainage systems, collapsed dams or levees, and other similar events. If water comes from above, such as rain or melting snow, overflowing gutters, and dripping into your inventory, your ordinary commercial property insurance will cover you.
              </Text>
              <UnorderedList spacing={3} mb={6}>
                <ListItem>
                  Plumbing; Electrical systems such as appliances and HVAC
                  systems
                </ListItem>
                <ListItem>Carpeting</ListItem>
                <ListItem>Cabinets, paneling, and bookshelves</ListItem>
                <ListItem>Windows</ListItem>
                <ListItem>Anchorage systems and foundation walls</ListItem>
                <ListItem>
                  Staircases, as well as fuel tanks, well tanks, and pumps
                </ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                How do I know if I need it?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              Standard business insurance plans often exclude flood coverage, and various states require business owners to acquire commercial flood insurance as a separate policy. Flooding may happen everywhere. Flooding is not limited to coastal locations or tidal/river basins, and it often happens outside of FEMA's high-risk flood zones.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              w={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                What are the key exclusions to Commercial Flood Insurance?
              </Heading>
              <UnorderedList spacing={3} mb={6}>
                <ListItem>
                  Moisture, mildew, or mold damage that the property owner might
                  have averted
                </ListItem>
                <ListItem>
                  Damage from sewage or drain backup, unless a flood caused the
                  blockage
                </ListItem>
                <ListItem>
                  Currency, precious metals, or valuable documents
                </ListItem>
                <ListItem>
                  Landscaping and drainage systems located outside your building
                </ListItem>
              </UnorderedList>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need Commercial Flood insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Commercial Flood insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default CommercialFloodInsurance;
