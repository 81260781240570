import React from 'react';
import {
    Box,
    Text,
    Link,
    Button,
    VStack,
    Heading,
    Flex,
    ChakraProvider,
} from '@chakra-ui/react';
import { FaRegBuilding, FaUsers, FaMoneyBillWave } from 'react-icons/fa'
import { ChevronRightIcon } from '@chakra-ui/icons';
import { motion, AnimatePresence } from 'framer-motion';
import TopHeaderBar from './components/Top';
import NavigationHeader from './components/NavigationHeader';
import FooterBar from './components/GetAQuote';
import ContactUsForm from './components/ContactUsForm';
import Footer from './components/Footer';

const MotionBox = motion(Box);

function Feature({ icon: IconComponent, title, children, button }) {
    return (
        <Box
            borderWidth={1}
            borderRadius="lg"
            p={4}
            mb={4}
            w="100%"
            boxShadow="md"
            bg="white"
        >
            <Heading as="h2" size="xl" color="gray.700" mb={2}>
                <IconComponent size="1.5em" color="teal.500" />
                {' '}
                {title}
            </Heading>
            <Text fontFamily={"body"} color="gray.700">
                {children}
            </Text>
            {button ? button : null}
        </Box>
    );
}

function AboutUs() {
    return (
        <ChakraProvider>
            <TopHeaderBar />
            <NavigationHeader />
            <Box minHeight="calc(100vh - 4rem - 4rem)" backgroundColor="gray.100" paddingTop={"174px"}>
                <VStack
                    as="section"
                    spacing="6"
                    alignItems="start"
                    maxW="7xl"
                    px={{ base: '4', md: '8', lg: '16' }}
                    py="12"
                    mx="auto"
                    backgroundColor="white"
                    borderRadius="lg"
                    boxShadow="lg"
                    p={{ base: '4', md: '8', lg: '16' }}
                >
                    <Box boxShadow={"lg"} w="100%" mx={"auto"} p={"12"} borderRadius={"24"} bg={"#BC3B4A"} display="flex" justifyContent="center">
                        <MotionBox
                            animate={{ scale: [1, 1.1, 1] }}
                            transition={{ duration: 2 }}
                        >
                            <Flex alignItems="center" flexDir={"column"}>
                                <Box
                                    borderRadius="lg"
                                    px={2}
                                    py={1}
                                    mr={4}
                                ></Box>
                                <Heading as="h1" size="2xl" color="white">
                                    Lia Insurance Agency
                                </Heading>
                                <AnimatePresence>
                                    <motion.hr
                                        initial={{ width: 0 }}
                                        animate={{ width: "60%" }}
                                        transition={{ duration: 1, delay: 1 }}
                                        style={{
                                            borderTop: "2px solid black",
                                            width: "60%",
                                            alignSelf: "center",
                                            marginBottom: "1em",
                                            marginTop: "1em"
                                        }}
                                    />
                                </AnimatePresence>
                                <Text
                                    fontSize="lg"
                                    fontWeight="medium"
                                    color="white"
                                    mb="2"
                                    mt={"1em"}
                                    textAlign="center"
                                    w="75%"
                                >
                                    <b>Lia Insurance</b> is a family-owned independent insurance agency that specializes in
                                    homeowners, renters, business, umbrella, motorcycle, and automotive
                                    insurance across New York, Connecticut, and coming soon to Massachusetts.
                                </Text>
                            </Flex>
                        </MotionBox>
                    </Box>
                    <Feature
                        icon={FaRegBuilding}
                        title="Top Insurance Carriers"
                    >
                        We have access to several top{' '}
                        <Link href="/agency-partners" color="teal.500">
                            insurance carriers
                        </Link>{' '}
                        in the industry that we work with, which allows us to do the shopping for
                        you. We find the best price for your insurance coverage needs. We'll find you
                        the best price for your needs.
                    </Feature>
                    <Feature
                        icon={FaUsers}
                        title="Quality Customer Service"
                        button={<Button
                            as={Link}
                            href="/our-team"
                            alignSelf="start"
                            leftIcon={<ChevronRightIcon />}
                            mt={"1em"}
                            bg={"gray.600"}
                            color={"white"}
                            _hover={{bg: "gray.700"}}
                        >
                            Meet the Team
                        </Button>}
                    >
                        The agency was started by the Lia family in 2018 as an expansion of the Lia Auto Group. The Lia brand is
                        well known for its quality customer service.
                    </Feature>
                    <Feature
                        icon={FaMoneyBillWave}
                        title="Save Money"
                    >
                        Our corporate headquarters is located in Guilderland, NY. We have a team of trusted
                        insurance agents who will do the work for you. We believe in building
                        relationships with our customers. It is important to us that we provide
                        informative decisions to our clients throughout the whole process. Over
                        the years, we have saved customers thousands! Getting a quote is free,
                        easy and only takes 15 minutes! See if we can save you money.
                    </Feature>
                    <Box w="100%" display={"flex"} justifyContent={"center"}>
                        <ContactUsForm borderRadius={"10px"} width={{ base: "100%", md: "80%" }} />
                    </Box>
                </VStack>
            </Box>
            <FooterBar />
            <Footer mb={12}/>
        </ChakraProvider>
    );

}

export default AboutUs;
