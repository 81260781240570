import React, { useState, useRef } from 'react';
import {
  Flex,
  Button,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ChakraProvider,
  useMediaQuery,
  IconButton,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useColorModeValue,
  VStack
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, HamburgerIcon } from '@chakra-ui/icons';
import Logo from '../images/logo.png';

const NavigationHeader = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);
  const btnRef = useRef();

  const [isSmallScreen] = useMediaQuery('(max-width: 800px)');

  const handleButtonClick = () => {
  
    if (window.location.pathname === '/quote') {
      window.location.reload();
    }
  };

  const mobileButtons = () => (
    <VStack spacing={4}>
      <Link to="/">
        <Button
          fontWeight={'medium'}
          color="white"
          mx="4"
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
        >
          Home
        </Button>
      </Link>
      <Menu >
        <MenuButton
          as={Button}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          fontWeight={'medium'}
          color="white"
          rightIcon={<ChevronDownIcon />}
        >
          Insurance
        </MenuButton>
        <MenuList
          fontWeight={'medium'}
          backgroundColor="gray.200"
          border="none"
          mt={2}
          py={2}
          boxShadow="lg"
          borderRadius="md"
          _focus={{ outline: 'none' }}
        >
          <Link to="/insurance/personal">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Personal
            </MenuItem>
          </Link>
          <Link to="/insurance/business">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Business
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      <Menu >
        <MenuButton
          as={Button}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          fontWeight={'medium'}
          color="white"
          rightIcon={<ChevronDownIcon />}
        >
          About
        </MenuButton>
        <MenuList
          fontWeight={'medium'}
          backgroundColor="gray.200"
          border="none"
          mt={2}
          py={2}
          boxShadow="lg"
          borderRadius="md"
          _focus={{ outline: 'none' }}
        >
          <Link to="/about">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              About Us
            </MenuItem>
          </Link>
          <Link to="/our-team">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Our Team
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      <Link to="/pay-bill">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Pay Bill
        </Button>
      </Link>
      <Link to="/file-claim">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          File Claim
        </Button>
      </Link>
      <Link to="/agency-partners">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Agency Partners
        </Button>
      </Link>
      <Link to="/contact">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Get Quote
        </Button>
      </Link>
      <Link to="/contact">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Contact Us
        </Button>
      </Link>
      <Link to="/careers">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Careers
        </Button>
      </Link>
      <Menu >
        <MenuButton
          as={Button}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          fontWeight={'medium'}
          color="white"
          rightIcon={<ChevronDownIcon />}
        >
          Resources
        </MenuButton>
        <MenuList
          fontWeight={'medium'}
          backgroundColor="gray.200"
          border="none"
          mt={2}
          py={2}
          boxShadow="lg"
          borderRadius="md"
          _focus={{ outline: 'none' }}
        >
          <Link to="/blog">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Blog
            </MenuItem>
          </Link>
          <Link to="/defensive-driving">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Defensive Driving Course
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      </VStack>
  );
  const desktopButtons = () => (
    <Flex direction={"row"} alignItems={"center"}>
      <Menu >
        <MenuButton
          as={Button}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          fontWeight={'medium'}
          color="white"
          rightIcon={<ChevronDownIcon />}
        >
          Insurance
        </MenuButton>
        <MenuList
          fontWeight={'medium'}
          backgroundColor="gray.200"
          border="none"
          mt={2}
          py={2}
          boxShadow="lg"
          borderRadius="md"
          _focus={{ outline: 'none' }}
        >
          <Link to="/insurance/personal">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Personal
            </MenuItem>
          </Link>
          <Link to="/insurance/business">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Business
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      <Menu >
        <MenuButton
          as={Button}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          fontWeight={'medium'}
          color="white"
          rightIcon={<ChevronDownIcon />}
        >
          About Us
        </MenuButton>
        <MenuList
          fontWeight={'medium'}
          backgroundColor="gray.200"
          border="none"
          mt={2}
          py={2}
          boxShadow="lg"
          borderRadius="md"
          _focus={{ outline: 'none' }}
        >
          <Link to="/about">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              About
            </MenuItem>
          </Link>
          <Link to="/our-team">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Our Team
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      
      <Link to="/pay-bill">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Pay Bill
        </Button>
      </Link>
      <Link to="/file-claim">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          File Claim
        </Button>
      </Link>
      <Link to="/agency-partners">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Agency Partners
        </Button>
      </Link>
      <Link to="/quote" onClick={handleButtonClick}>
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Get Quote
        </Button>
      </Link>
      <Link to="/contact">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Contact Us
        </Button>
      </Link>
      <Link to="/careers">
        <Button
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          bgColor={'transparent'}
          fontWeight={'medium'}
          color="white"
          mx="4"
        >
          Careers
        </Button>
      </Link>
      <Menu >
        <MenuButton
          as={Button}
          backgroundColor={'transparent'}
          _hover={{ backgroundColor: 'rgba(255, 255, 255, 0.5)' }}
          fontWeight={'medium'}
          color="white"
          rightIcon={<ChevronDownIcon />}
        >
          Resources
        </MenuButton>
        <MenuList
          fontWeight={'medium'}
          backgroundColor="gray.200"
          border="none"
          mt={2}
          py={2}
          boxShadow="lg"
          borderRadius="md"
          _focus={{ outline: 'none' }}
        >
          <Link to="/blog">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Blog
            </MenuItem>
          </Link>
          <Link to="/defensive-driving-course">
            <MenuItem
              fontWeight={'medium'}
              color="black"
              _hover={{
                backgroundColor: 'gray.400',
                color: 'white',
                transition: 'all 0.25s',
              }}
              backgroundColor={'gray.200'}
            >
              Defensive Driving Course
            </MenuItem>
          </Link>
        </MenuList>
      </Menu>
      </Flex>
  );
  const bg = useColorModeValue('#BC3B4A', '#BC3B4A');


  return (
    <ChakraProvider>
      <Flex
      position="fixed"
      top="60px"
      left="0"
      right="0"
      zIndex={1000}
      backgroundColor={bg}
      justifyContent="space-between"
      alignItems="center"
      p="4"
    >
        <Link to="/"><Image src={Logo} alt="Logo" boxSize="40px" width="180px" height="90px" /></Link>
        {isSmallScreen ? (
          <>
            <IconButton
              ref={btnRef}
              colorScheme="teal"
              icon={<HamburgerIcon />}
              onClick={() => setIsOpen(!isOpen)}
            />
            <Drawer
              isOpen={isOpen}
              placement="right"
              onClose={onClose}
              finalFocusRef={btnRef}
            >
              <DrawerOverlay>
                <DrawerContent bg={bg}>
                  <DrawerCloseButton />
                  {/* <DrawerHeader borderBottomWidth="1px">Menu</DrawerHeader> */}
                  <DrawerBody>
                    <Flex direction="row">
                      {mobileButtons()}
                    </Flex>
                  </DrawerBody>
                </DrawerContent>
              </DrawerOverlay>
            </Drawer>
          </>
        ) : (
          <Flex py="4">{desktopButtons()}</Flex>
        )}
      </Flex>
    </ChakraProvider>
  );
};

export default NavigationHeader;


