import {
    Box,
    Heading,
    Text,
    Button,
    UnorderedList,
    ListItem,
    VStack,
    useColorModeValue,
    Container,
    Divider
} from "@chakra-ui/react";
import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "./Banner";
import RentersInsurancePic from "../../images/personal-insurance/RentersInsurance.jpg"

function RentersInsurance() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");

  
    return (
        <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
            <TopHeaderBar />
            <NavigationHeader />
            <Box pb={"4em"}>
                <Banner img={RentersInsurancePic} header={"Renters Insurance"} footer={"Protect Your Belongings At An Affordable Rate"} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Do I need renters insurance?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                If you rent a house, condo, apartment, townhouse, etc. you need renters insurance and often, the landlord will require you to have it. Even though you do not own the building, your personal belongings inside are not covered. Renters insurance can help cover your belongings if they get damaged or if your apartment becomes uninhabitable. If you can no longer live there, your temporary living expenses of a hotel or other living expenses can be covered depending on the insurance company.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                What type of damage does renters insurance cover?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                Renter's insurance often covers fire & smoke damage, vandalism & theft, weather damage, water damage, visitor injuries, and more, depending on the type of coverage you have. Coverage varies by insurance company so make sure to check with your agent you have a policy for what you need.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                How much does renters insurance cost?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                Renter's insurance is typically very affordable. One way to lower the cost is to increase your deductible. However, in case of a loss consider how much you are willing to pay if you need to make a claim.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                How can you save money on my renter's insurance?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                Some ways to save money on your renters insurance include:
                            </Text>

                            <UnorderedList mb={"1.5em"}>
                                <ListItem>Bunding your renter's insurance and your auto insurance</ListItem>
                                <ListItem>Pay in full annually</ListItem>
                                <ListItem>The deductible amount on the policy</ListItem>
                                <ListItem>Home security systems</ListItem>
                                <ListItem>Sprinklers or smoke alarms</ListItem>
                            </UnorderedList>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}>

                            <Heading as="h3" size="lg" mb={4}>
                                Save Time and Money with Lia Insurance
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                If you need renters insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop different renters insurance carriers to find the best deal for you!
                            </Text>
                            <Divider borderColor={borderColor} mb={6} />
                            <Link to={"/contact"}>
                                <Button colorScheme="teal" size="lg" >
                                    CONTACT US
                                </Button>
                            </Link>

                        </Box>

                    </VStack>
                </Container>
            </Box>
            <FooterBar />
        </Box>
    );
}

export default RentersInsurance;




