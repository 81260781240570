import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Divider,
  Link,
  Button,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import Liability from "../../images/business-insurance/error.jpg";

function Omissions() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"E & O Insurance"}
          footer={"Protect Losses Not Covered By Traditional Liability Insurance"}
          img={Liability}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is Errors and Omissions insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Error and Omission Insurance is a type of professional liability insurance that protects businesses, their employees, and other professionals from allegations of poor work or negligent behavior. In the event that a client claims a financial loss for your company's error, omission, or otherwise negligent Error and Omission Insurance will help your business.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What E&O Insurance Covers.
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Court expenses and settlements up to the amount indicated in the
                insurance contract are frequently covered by errors and
                omissions insurance as well as:
                <UnorderedList spacing={3} mb={6}>
                  <ListItem>
                    Failure to fulfill a certain standard of care, particularly
                    one established by a specific profession
                  </ListItem>
                  <ListItem>Contract violation</ListItem>
                  <ListItem>
                    Errors, or oversights that occur while working
                  </ListItem>
                  <ListItem>
                    Failure to fulfill a deadline or provide a promised service
                    to a customer
                  </ListItem>
                  <ListItem>Professional blunder</ListItem>
                </UnorderedList>
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Who Needs E&O Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                This type of liability insurance is typically necessary for companies that provide professional advice or services. Without E&O insurance, a firm can be held accountable for up to millions of dollars in damages, plus the costs of a legal team. E&O insurance is available to insurance brokers, realtors, licensed investment advisers, financial planners, lawyers, others working with legal contracts, and other financial professionals. Outside the financial industry, business-like Nonprofits, general maintenance companies and contractors, engineering firms, and any other corporation or professionals who perform a service, such as wedding planners, doctors, dentists, and other medical professionals.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Why is Errors and Omissions Insurance Important?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                A customer may sue a financial expert if they suffer a loss due to an error or oversight made during the application, consultation, or investment decision process. Legal expenses and any damages awarded to the client are covered under E&O insurance.
              </Text>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
              If you need Error's & Omissions insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Error's & Omissions insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link as={"a"} to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default Omissions;
