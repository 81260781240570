import {
  Box,
  Heading,
  Text,
  VStack,
  useColorModeValue,
  Container,
  Divider,
  Link,
  Button,
  ListItem,
  UnorderedList
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import stock from "../../images/business-insurance/stock.jpeg"


function GeneralLiabilityInsurance() {
  const bgColor = useColorModeValue("gray.50", "gray.800");
  const boxBgColor = useColorModeValue("white", "gray.700");
  const boxShadow = useColorModeValue("lg", "dark-lg");
  const borderColor = useColorModeValue("gray.300", "gray.600");

  return (
    <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
      <TopHeaderBar />
      <NavigationHeader />
      <Box pb={"4em"}>
        <Banner
          header={"General Liability Insurance"}
          footer={"Stay Safe With Our General Liability Insurance"}
          img={stock}
        />

        <Container maxW="container.xl">
          <VStack spacing={6} my={6}>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What is General Liability Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Commonly referred to as small business liability insurance or commercial liability insurance, general liability (GL) insurance provides comprehensive safeguards for your company against third-party allegations involving physical injuries, property harm, or personal injuries.
              </Text>
            </Box>
            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                What does General Liability Insurance cover?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                General liability insurance policies typically cover you and your company for claims involving physical harm and property damage brought on by your activities, services, or goods. It could also protect you in the event that you are accused of causing harm to your landlord's property.
              </Text>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
              width={"100%"}
            >
              <Heading as="h2" size="xl" mb={6}>
                Who Needs General Liability Insurance Coverage?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                Businesses that utilize General Liability Insurance include:
              </Text>
              <UnorderedList spacing={3} mb={6}>
                <ListItem>Artisan contractors</ListItem>
                <ListItem>Small business owners</ListItem>
                <ListItem>Landscaping companies</ListItem>
                <ListItem>IT contractors</ListItem>
                <ListItem>Real estate agents</ListItem>
                <ListItem>Consulting</ListItem>
                <ListItem>Marketing</ListItem>
                <ListItem>Janitorial services</ListItem>
                <ListItem>And more</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Why Do I Need General Liability Insurance?
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                You may be held liable as a small company owner if someone is
                harmed on your property, in your business, or as a result of
                your actions. Make sure your company is adequately
                risk-protected. Consider purchasing general liability insurance
                if you or your staff members:
              </Text>
              <UnorderedList spacing={3} mb={6}>
                <ListItem>Interact with clients face to face</ListItem>
                <ListItem>Have access to customer property</ListItem>
                <ListItem>Use advetising to market your company</ListItem>
                <ListItem>Use third-party locations for business-related activities</ListItem>
                <ListItem>Require coverage to be considered for a project</ListItem>
              </UnorderedList>
            </Box>

            <Box
              p={6}
              bg={boxBgColor}
              boxShadow={boxShadow}
              borderRadius="lg"
              mb={10}
            >
              <Heading as="h2" size="xl" mb={6}>
                Save Time and Money with Lia Insurance
              </Heading>
              <Text fontSize="lg" mb={"1.5em"}>
                If you need General Liability Insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, <Link href="tel:15185793153">give Lia Insurance Agency a call today</Link>. We do the hard work for you and shop for different valuable possessions insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your valuables!
              </Text>
              <Divider borderColor={borderColor} mb={6} />
              <Link to="/contact">
                <Button colorScheme="teal" size="lg">
                  CONTACT US
                </Button>
              </Link>
            </Box>
          </VStack>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
}

export default GeneralLiabilityInsurance;
