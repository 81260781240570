import {
    Box,
    Heading,
    Text,
    Button,
    VStack,
    useColorModeValue,
    Container,
    Divider,
    Link
} from "@chakra-ui/react";
// import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "./Banner";
import OffRoadInsurancePic from "../../images/personal-insurance/OffroadInsurance.jpg"

function OffRoadInsurance() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");

    return (
        <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
            <TopHeaderBar />
            <NavigationHeader />
            <Box pb={"4em"}>
                <Banner header={"Off-Road Insurance "} footer={"Affordable Off-Road Vehicle Insurance"} img={OffRoadInsurancePic} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                            What does Off-road Vehicle Insurance cover?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            Accidents can happen and you should be protected. An off-road vehicle is used on dirt or non-asphalt trails such as an ATV, bikes, or snowmobiles. It includes bodily injury and property damage. You can also include collision, comprehensive, medical, and underinsured/uninsured insurance coverage. 
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                            Is Off-road Vehicle Insurance required by law?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            Some states do require it by law, but if you want to use public trails, some require having insurance before use. Insurance regulations differ by state, check your state laws for more information. 

                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                
                                Does Homeowners Insurance cover an off-road vehicle?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            In most cases, homeowners insurance does not extend and frequently excludes coverage of off-road vehicles and off-road injuries. However, some home insurance personal liability coverages will provide protection with exceptions. Ask your insurance representative if you are covered.

                            </Text>
                        </Box>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                
                            Are customizations you make to your off-road vehicle covered?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            Customizations on an off-road vehicle complicate the coverage. Changes to your vehicle can alter your policy and limits, but your carrier may exclude anything that is not OEM parts. If any updates are made, inform your carrier to see if you can get coverage.

                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}>
                                                
                            <Heading as="h3" size="lg" mb={4}>
                                Save Time and Money with Lia Insurance
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            If you need off-road vehicle insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, <Link href="tel:15185793153">give Lia Insurance Agency a call today.</Link> We do the hard work for you and shop for different off-road vehicle insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect you.

                            </Text>
                            <Divider borderColor={borderColor} mb={6} />
                            <Link to={"/contact"}>
                            <Button colorScheme="teal" size="lg">
                                CONTACT US
                            </Button>
                            </Link>
                        </Box>

                    </VStack>
                </Container>
            </Box>
            <FooterBar />
        </Box>
    );
}

export default OffRoadInsurance;

/*




Does homeowners insurance cover an off-road vehicle?



In most cases, homeowners insurance does not extend and frequently excludes coverage of off-road vehicles and off-road injuries. However, some home insurance personal liability coverages will provide protection with exceptions. Ask your insurance representative if you are covered.



Are customizations you make to your off-road vehicle covered?



Customizations on an off-road vehicle complicated the coverage. Changes to your vehicle can alter your policy and limits, but your carrier may exclude anything that is not OEM parts. If any updates are made, inform your carrier to see if you can get coverage.



Save Time and Money with Lia Insurance



If you need off-road vehicle insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different off-road vehicle insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect you.



*/