import {
    Box,
    Heading,
    Text,
    UnorderedList,
    ListItem,
    VStack,
    useColorModeValue,
    Container,
    Divider,
    Link,
    Button
} from "@chakra-ui/react";
import NavigationHeader from "../../components/NavigationHeader";
import Footer from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "../Personal-Insurance/Banner";
import Liquor from "../../images/business-insurance/liquor.jpg"

function LiquorLiabilityInsurance() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");
    const borderColor = useColorModeValue("gray.300", "gray.600");


    return (
        <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
            <TopHeaderBar />
            <NavigationHeader />
            <Box pb={"4em"}>
                <Banner header={"Liquor Liability Insurance"} footer={"Keep Your Liquor Protected"} img={Liquor} fontSize={"1.5em"} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                What is Liquor Liability Insurance
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                Liquor liability insurance covers legal bills, settlements, and medical costs if alcohol is supplied to an intoxicated individual who subsequently hurts people or damages property.
                            </Text>
                        </Box>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Why is Liquor Liability Insurance important?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                In order to get a liquor license, most states require companies to have liquor liability insurance. You may also require it to sign a commercial lease or a loan arrangement. Although general liability insurance covers the majority of incidents, it usually does not cover alcohol-related liabilities. That's where liquor liability insurance comes in.
                            </Text>
                        </Box>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                You might need Liquor Liability Insurance in order to:
                            </Heading>
                            <UnorderedList>
                                <ListItem>Offer beer, wine, or liquor</ListItem>
                                <ListItem>Serve alcoholic beverages</ListItem>
                                <ListItem>Produce or distribute alcoholic products</ListItem>
                                <ListItem>Get a liquor license</ListItem>
                                <ListItem>Sign a business lease or loan agreement</ListItem>
                                <ListItem>In a state with dram shop regulations, protect yourself from financial damages</ListItem>

                            </UnorderedList>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                What does liquor liability insurance cover?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                Liquor liability insurance protects your business from liability claims made by third those outside your company that hold your company liable for damages caused by an inebriated individual. The insurance covers the costs of personal harm or property damage caused by the sale or service of alcoholic beverages. For example, liquor liability insurance would pay your legal fees if your bar serves an inebriated customer who starts a fight or damages someone's property. This coverage covers your legal fees if someone sues you for physical harm or property damage caused by someone who was sold or served alcohol by your establishment.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Who needs liquor liability coverage?
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                                Liquor liability insurance is required for every business that sells or serves alcohol to protect itself against financial losses or to comply with state laws and contracts. Several sectors, in particular, require this coverage more frequently than others, including:
                            </Text>
                            <UnorderedList spacing={3} mb={6}>
                                <ListItem>Food and beverage business</ListItem>
                                <ListItem>Retailers</ListItem>
                                <ListItem>Manufacturer and distributors</ListItem>
                            </UnorderedList>
                        </Box>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Save Time and Money with Lia Insurance
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                            If you need Liquor Liability insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different Liquor Liability insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your business!

 
                            </Text>
                            <Divider borderColor={borderColor} mb={6} />
                            <Link to="/contact">
                                <Button colorScheme="teal" size="lg">
                                    CONTACT US
                                </Button>
                            </Link>
                        </Box>
                    </VStack>
                </Container>
            </Box>
            <Footer />
        </Box>
    );
}

export default LiquorLiabilityInsurance;
