import {
    Box,
    Heading,
    Text,
    Button,
    UnorderedList,
    ListItem,
    Divider,
    VStack,
    useColorModeValue,
    Container,
} from "@chakra-ui/react";
import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import Top from "../../components/Top"
import Banner from "./Banner";
import ValuablePossesionInsurancePic from "../../images/personal-insurance/ValuablePossessionInsurance.jpg"


function ValuablePossessionInsurance() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");
    const valuablesList = [
        "Jewelry",
        "Furs",
        "Artwork",
        "Baseball card collections",
        "Golf equipment",
        "Collectables such as coins or stamps",
        "Wine collections",
    ];

    return (
        <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
            <Top />
            <NavigationHeader />
            <Box pb={"4em"}>
                <Banner header={"Valuable Possession Insurance"} footer={"Accidents Happen. Protect Your Valuables. NY, CT & MA"} img={ValuablePossesionInsurancePic} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                            Accidents Happen. Protect Your Valuables. 
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                            Stones fall out of diamond rings, accidents happen. We'll get you the right protection for your valuable belongings and make sure you are covered in the event of a loss, theft, accident, or natural disaster. 
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                What does Valuable Possession Insurance include?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                Typically you would need valuable possession insurance to cover expensive possessions in the event of a total loss. This would ensure you get the actual value to replace what was lost versus a generic market price value. Additionally, it can provide additional coverage for accidental damage done to these valuable items.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                Does my Homeowner's Insurance cover my valuable possessions?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            Yes. Even then, you may not get as much in a claim for the actual replacement cost of the item, so it is good to get an appraisal and protect yourself with the additional coverage you would need with valuable possessions insurance. This will ensure that if any loss occurs, you will not get the generic market price to replace your priceless items.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                What kind of valuables can be covered?
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                            It depends on the carrier and coverage, but here are some things that can be covered:
                            </Text>

                            <UnorderedList spacing={3} mb={6}>
                                {valuablesList.map((item, index) => {
                                    return <ListItem key={index}>{item}</ListItem>;
                                })}
                            </UnorderedList>
                            <Text fontSize="lg" mb={"1.5em"}>
                            Those are just a few of the many items that can be covered when insured with a valuable possession policy. They are typically also covered by your homeowner's insurance, but if you need higher limits, this is where Valuable Possesions Insurance would come in.
                            </Text>
                        </Box>

                        <Box
                            p={6}
                            alignSelf="flex-start" // Add this line
                            width="100%" // Add this line
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                Save Time and Money with Lia Insurance
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                If you need valuable possession insurance and are looking to get a quote on a new insurance policy or simply want to talk to a licensed agent about your current policy, give Lia Insurance Agency a call today. We do the hard work for you and shop for different valuable possessions insurance carriers to find the best deal for you! It is better to have insurance coverage and not need it than to need it and not have it. Let us protect your valuables!
                            </Text>

                            <Divider borderColor={borderColor} mb={6} />
                            <Link to={"/contact"}>
                                <Button colorScheme="teal" size="lg">
                                    CONTACT US
                                </Button>
                            </Link>
                        </Box>

                    </VStack>
                </Container>
            </Box>
            <FooterBar/>
        </Box>
    );
}

export default ValuablePossessionInsurance;

