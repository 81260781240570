import React from "react";
import TopHeaderBar from "./components/Top";
import FooterBar from "./components/GetAQuote";
import NavigationHeader from "./components/NavigationHeader";
import { Box, ChakraProvider, Flex, Grid } from "@chakra-ui/react"
import AgencyCard from "./components/AgencyCard";
import Banner from "./pages/Personal-Insurance/Banner";
import PartnersPic from "./images/misc/Partners.jpeg"
import Footer from "./components/Footer";

function AgencyPartners() {
  const agencies = [{
    header: "National General",
    name: "nationalgeneral",
    website: "http://www.nationalgeneral.com/",
    phoneNumber: "1-800-325-1088"
  },
  {
    header: "Safeco Insurance",
    name: "safeco",
    website: "http://www.safeco.com/",
    phoneNumber: "1-800-332-3226"
  },
  {
    header: "Utica National",
    name: "uticanationalinsurancegroup",
    website: "https://uticanational.com/",
    phoneNumber: "1-800-598-8422"
  },
  {
    header: "Leatherstocking Cooperative Insurance Company",
    name: "leatherstockingcooperative",
    website: "http://www.leatherstockinginsurance.com/",
    phoneNumber: "1-607-547-2007"
  },
  {
    header: "Travelers",
    name: "travelers",
    website: "https://travelers.com/",
    phoneNumber: "1-800-842-5075"
  },
  {
    header: "Foremost (formerly Metlife)",
    name: "foremost",
    website: "http://www.metlife.com/",
    phoneNumber: "1-800-854-6011"
  },
  {
    header: "Progressive",
    name: "progressive",
    website: "http://www.progressive.com/",
    phoneNumber: "1-800-237-2060"
  },
  {
    header: "Encompass",
    name: "encompass",
    website: "http://www.encompassinsurance.com/",
    phoneNumber: "1-800-588-7400"
  },
  {
    header: "Preferred Mutual",
    name: "preferredmutual",
    website: "http://www.preferredmutual.com/",
    phoneNumber: "1-800-333-7642"
  },
  {
    header: "Foremost Insurance Group",
    name: "foremostinsurancegroup",
    website: "https://www.foremost.com/",
    phoneNumber: "1-800-237-2060"
  },
  {
    header: "New York Central Mutual",
    name: "newyorkcentralmutual",
    website: "http://www.nycm.com/",
    phoneNumber: "1-800-234-6926"
  },
  {
    header: "Hagerty",
    name: "hagerty",
    website: "https://www.hagerty.com/insurance",
    phoneNumber: "1-800-922-9701"
  },
  ]
  const lastRowCount = agencies.length % 3;
  const lastRowIndex = agencies.length - lastRowCount;


  return (
    <ChakraProvider>
      <Box height={"100%"} marginBottom={"100px"} paddingTop={"174px"}>
        <TopHeaderBar />
        <NavigationHeader />
        <Banner img={PartnersPic} header={"Meet our Agency Partners"} footer={
          "When you get a quote from Lia Insurance Agency we shop for the best rates and coverage for you at these agency partners to find you the best deal. Learn more, pay bills, or file a claim with your provider below."} />
        <Flex height={"100%"} width={"100%"} flexDirection={"column"} bgGradient="linear(to-r, gray.100, gray.200)">
          <Box height={"100%"}>
            <Flex
              className="test"
              flexDirection={"column"}
              alignItems={"center"}
              justifyContent={"center"}
              width={"100%"}
            >
              <Grid
                templateColumns={{
                  base: "repeat(1, 1fr)",
                  md: "repeat(3, 1fr)",
                }}
                container
                justify="flex-end"
                gap={8}
                py={8}
              >
                {agencies.slice(0, lastRowIndex).map((agency) => (
                  <AgencyCard
                    header={agency.header}
                    name={agency.name}
                    website={agency.website}
                    phoneNumber={agency.phoneNumber}
                  />
                ))}
              </Grid>
              {/* Check if last row is not full and render it with Flex to center the item */}
              {lastRowCount > 0 && lastRowCount < 3 && (
                <Flex
                  direction={{ base: "column", md: "row" }}
                  justify="center"
                  wrap="wrap"
                  mt={6}
                >
                  {agencies.slice(lastRowIndex).map((agency) => (
                    <AgencyCard
                      header={agency.header}
                      name={agency.name}
                      website={agency.website}
                      phoneNumber={agency.phoneNumber}
                    />
                  ))}
                </Flex>
              )}
            </Flex>
          </Box>
        </Flex>
        <Footer/>
        <FooterBar />
      </Box>
    </ChakraProvider>
  );


}

export default AgencyPartners;