import React from 'react';
import { ChakraProvider, Box, Grid,  Heading, Text, useColorModeValue, Button, Link, useMediaQuery } from '@chakra-ui/react';
import NavigationHeader from './components/NavigationHeader';
import FooterBar from './components/GetAQuote';
import theme from "./theme";
import TopHeaderBar from './components/Top';
import Banner from './pages/Personal-Insurance/Banner'
import CareerImg from './images/misc/CareerBanner.png'
import Footer from './components/Footer';

const jobListings = [
  {
    title: 'Insurance Agent - NY',
    subtitle: 'Personal Lines',
    description: 'Prepares and presents quotes for customers with our large selection of quality carriers', 
    link: 'https://liacars.isolvedhire.com/jobs/1450344'
  }
  // {
  //   title: 'Commercial Lines',
  //   subtitle: 'Insurance Producer',
  //   description: 'Identify opportunities, develop relationships and close new commercial business',
  //   link: 'https://liacars.isolvedhire.com/jobs/1267316'
  // },
  // {
  //   title: 'Personal Lines', 
  //   subtitle: 'Insurance Sales Manager',
  //   description: 'Manages infrastructure and data related to insurance sales.', 
  //   link: 'https://liacars.isolvedhire.com/jobs/1267320'
  // },
  // Add more job listings here
];

function Careers() {
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const [isSmallScreen] = useMediaQuery('(max-width: 800px)');


  return (
    <ChakraProvider theme={theme}>
      <Box bg={"gray.100"} mb={isSmallScreen ? "16" : "16"} paddingTop={"174px"}>
        <TopHeaderBar />
        <NavigationHeader />
        <Box height={"100em"}>
        <Banner header={"Careers"} footer={"Lia Insurance is Hiring!"} img={CareerImg}/>
        
        <Box maxW="container.xl" mx="auto" p={6}>
          <Box bg="white" p={8} borderRadius="lg" boxShadow="xl" mb={8}>
            <Text fontSize="lg" mb={4}>
              Lia Insurance Agency is always looking for talented agents for all aspects of the agency. If you are a positive goal-oriented customer service focused individual with insurance experience in personal or commercial lines insurance products, then we might be just what you are looking for right now. Our agency is licensed to sell personal, commercial, life and health insurance.
            </Text>
            <Text fontSize="lg" mb={4}>
              Our agents enjoy a comprehensive benefit package that includes health, dental and vision coverage. A 401K program with employer matching, paid vacation and personal time and other perks.
            </Text>
            <Text fontSize="lg">
              If you are interested in joining our team, please send your resume to insurance@liagroup.com and let us know how you feel you can add value to our agency team.
            </Text>
          </Box>
        </Box>

        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6} padding={6}>
          {jobListings.map((job, index) => (
            <Box 
              key={index} 
              w="sm" 
              borderWidth="1px"  
              mx={"auto"}
              mt={"4em"}
              borderRadius="lg" 
              overflow="hidden"
              boxShadow="2xl" 
              bg={bgColor}
              transition="0.3s"
              _hover={{ transform: "scale(1.02)", boxShadow: "xl" }}
            >
              <Box p="6">
                <Box d="flex" alignItems="baseline">
                  <Heading size="md" ml="0" fontWeight="semibold" lineHeight="tight" isTruncated color="teal.500">
                    {job.title}
                  </Heading>
                  <Heading size="md" ml="0" fontWeight="semibold" lineHeight="tight" isTruncated color="teal.500">
                    {job.subtitle}
                  </Heading>
                </Box>

                <Box mt={2} color="gray.600">
                  <Text>
                    {job.description}
                  </Text>
                  <Link href={job.link} isExternal>
                    <Button mt={4} colorScheme="teal">Apply Now</Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          ))}
        </Grid>
        </Box>
        <FooterBar/>
        <Footer/>
      </Box>
    </ChakraProvider>
  );
}

export default Careers;

