import {
    Box,
    Heading,
    Text,
    Button,
    UnorderedList,
    ListItem,
    Divider,
    VStack,
    useColorModeValue,
    Container,
} from "@chakra-ui/react";
import { Link } from "react-router-dom"
import NavigationHeader from "../../components/NavigationHeader";
import FooterBar from "../../components/GetAQuote";
import TopHeaderBar from "../../components/Top";
import Banner from "./Banner";
import BoatInsurancePic from "../../images/personal-insurance/BoatInsurance.jpg"


function BoatInsurance() {
    const bgColor = useColorModeValue("gray.50", "gray.800");
    const borderColor = useColorModeValue("gray.300", "gray.600");
    const boxBgColor = useColorModeValue("white", "gray.700");
    const boxShadow = useColorModeValue("lg", "dark-lg");
    const savingTips = [
        "Bundle your boat policy with your home or auto for a multi-policy discount",
        "Take a boating safety course",
        "Maintain a clean driving record",
        "Onboard safety equipment/safety features of the boat",
        "Pay your premium in full, many carriers will discount your premium if you do this"
    ];

    return (
        <Box bg={bgColor} minHeight="100%" paddingTop={"174px"}>
            <TopHeaderBar />
            <NavigationHeader />

            <Box pb={"4em"}>
                <Banner header={"Boat Insurance"} footer={"Affordable Boat Insurance in New York"} img={BoatInsurancePic} />

                <Container maxW="container.xl">
                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                            width={"100%"}
                        >
                            <Heading as="h2" size="xl" mb={6}>
                                Boat Insurance
                            </Heading>
                            <Text fontSize="lg" mb={"1.5em"}>
                            In New York State boat insurance is not required to register a boat with DMV, but it is a good idea to fully protect it. It's especially important if you have a loan from a lender as they typically require you to have boat insurance. Here's a list of the five frequently asked questions that we see all the time here at the Lia Insurance Agency regarding boat insurance!
                            </Text>
                        </Box>
                    </VStack>

                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Text fontSize="lg" mb={"1.5em"}>
                                <Heading as="h3" size="lg" mt={4}>1. Is my boat covered by my Homeowners Insurance?</Heading>
                                It depends on your specific policy! Some homeowner's insurance policies do offer limited coverage for your boat. Most homeowners policies will provide some liability coverage extended from your home if the boat is under 25ft long and the motor is under 25hp.
                                <br/>

                                <Heading as="h3" size="lg" mt={4} mb={2}>2. Does my Boat Insurance extend to my boat trailer or will I need an additional policy?</Heading>
                                Boat insurance will provide coverage for a trailer. Your agent will need the VIN number from the trailer when writing your policy. Some boat insurance will provide roadside assistance and trailer trip interruption coverage should something happen while you are towing the boat.

                                <Heading as="h3" size="lg" mt={4}>3. What type of coverage is best for me?</Heading>
                                This is a question our agents love here at the Lia Insurance Agency. This allows us the opportunity to really get to know our customers and how they intend to use their boat. Here are some things to consider:

                                <UnorderedList>
                                    <ListItem>What type of boat do you have? (make, model, length, power, etc.)</ListItem>
                                    <ListItem>Who will be operating your boat?</ListItem>
                                    <ListItem>What will you be doing with the boat?</ListItem>
                                    <ListItem>Will the boat be at a dock for the season or will you be trailering it each time?</ListItem>
                                    <ListItem>Where will the boat be stored in the off season?</ListItem>
                                    <ListItem>Does the boat have any special equipment like an upgraded stereo or fishing equipment?</ListItem>
                                    <ListItem>Will the boat be used in fresh water, salt water or both?</ListItem>
                                    <ListItem>What accessories will you be using with the boat? Wakeboard, water skies, tubes, kneeboard, etc...?</ListItem>
                                </UnorderedList>

                                <Heading as="h3" size="lg" mt={4}>4. Is insurance for my boat expensive?</Heading>
                                The cost of the policy will depend on the value of the boat you are covering. There are some things you can do to help keep the cost as low as possible:

                                <UnorderedList spacing={3} mt={3}>
                                    {savingTips.map((tip, index) => (
                                        <ListItem key={index}>{tip}</ListItem>
                                    ))}
                                </UnorderedList>
                            </Text>
                        </Box>
                    </VStack>

                    <VStack spacing={6} my={6}>
                        <Box
                            p={6}
                            alignSelf="flex-start"
                            width="100%"
                            bg={boxBgColor}
                            boxShadow={boxShadow}
                            borderRadius="lg"
                            mb={10}
                        >
                            <Heading as="h3" size="lg" mb={4}>
                                Get in touch with us
                            </Heading>

                            <Text fontSize="lg" mb={"1.5em"}>
                                <Link to={"/our-team"}>Talk with a Lia Insurance Agent.</Link> We will shop your policy with our carriers to make sure you are getting the right coverage at the best price.
                            </Text>
                            <Divider borderColor={borderColor} mb={6} />
                            <Link to={"/contact"}>
                                <Button colorScheme="teal" size="lg">
                                    CONTACT US
                                </Button>
                            </Link>

                        </Box>
                    </VStack>
                </Container>
            </Box>
            <FooterBar />
        </Box>
    );
}

export default BoatInsurance;
